import { graphql } from "gatsby"
import React from "react"
import Card from "react-bootstrap/Card"
import { Helmet } from 'react-helmet'
import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SeoMeta from "../components/seo"

export const query = graphql`
  query {
    allFile(filter: {
      internal: { mediaType: { eq: "image/jpeg" } }
      relativeDirectory: { eq: "a-typical-day" }
    }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 512, height: 580, quality: 100)
          }
        }
      }
    }
  }
`

const aTypicalDay = ({ data }) => {
  const images = data.allFile.edges.reduce((prev, curr, idx, arr) => {
    const { node } = curr
    const n = /([0-9]+)\.jpg$/.exec(node.relativePath)
    prev[n[1]] = getSrc(node)
    return prev
  }, {})

  return (
    <Layout>
      <SeoMeta title="A Typical Day" />
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h3>A Typical Day in Preschool</h3>
            <p><b>6:30 - 8:15 Before Preschool Care</b> - Included with Full and Part-time </p>
            <p><i>Goals:</i> Play encourages creativity and imagination.</p>
            <p><b>8:15 - 9:00 Outdoor play</b> - (weather permitting) unstructured activities, free play.</p>
            <p><i>Goals:</i> Develop socially &amp; emotionally, develop gross motor skills, make choices, solve problems, use imagination, become independent, and develop conversational skills.</p>
            <p><b>9:00 - 9:30 Nutrition</b> – Dry cheerios and juice are served in the classroom.</p>
            <p><i>Goals:</i>  To meet health and physical needs while socializing.</p>
            <p><b>9:30-10:00 Circle Time</b> – Calendar, weather, monthly themes, letters, shapes, numbers, and colors.</p>
            <p><i>Goals:</i>  Participation helps children learn to interact with adults and children outside of the home. Also, learning to pay attention develops listening skills as well as respect for both teachers and peers.</p>
            <p><b>10:30 - 11:00 Morning lessons</b> - academics, show and tell, arts and crafts, songs, creative movement, dramatic play, table toys.</p>
            <p><i>Goals:</i>  Expand language skills, develop hand-eye coordination, exercise fine motor skills, expand self-esteem,<i> </i>grow perceptually &amp; cognitively, learn to share &amp; explore.</p>
            <p><b>11:00 - 11:25 Outdoor play</b> - (weather permitting) unstructured activities, free play.</p>
            <p><i>Goals:</i>  Develop socially &amp; emotionally, develop gross motor skills, make choices, solve problems, use imagination, become independent, and develop conversational skills.</p>
            <p><b>11:25 – 11:30 Clean up for lunch</b> - hand washing, toileting.</p>
            <p><i>Goals:</i>  Health awareness, learning responsibility, and practicing good hygiene habits.</p>
            <p><b>11:30 – 12:00 Lunch time</b> - supervised and served by the teachers.</p>
            <p><i>Goals:</i>  To meet health and physical needs while socializing.</p>
            <p><b>12:00 - 12:20 Prepare for nap time (bathroom time, lay out beds and blankets)</b></p>
            <p><b>12:20 - 2:30 Rest period</b> - quiet time.</p>
            <p><i>Goals:</i> Relaxation and unwinding. Rest Body and mind.</p>
            <p><b>2:30 - 3:00 Snack time</b> – Graham crackers and milk or fresh fruit are served in the classroom.</p>
            <p><i>Goals:</i>  To meet health and physical needs while socializing.</p>
            <p><b>3:00 - 4:00 Outdoor play</b> - (weather permitting) unstructured activities, free play.</p>
            <p><b>4:00 - 4:30 Story time, play dough, books, music, and puppets.</b></p>
            <p><i>Goals:</i>  Develop sensory and auditory skills, strengthen hand-eye coordination skills,&nbsp;use self-expression, begin group identification, and build language skills and development</p>
            <p><b>4:30 - 6:30 After Preschool Care</b> – Included with Full-time</p>
            <p><i>Goals</i>: Develop dexterity, physical, cognitive, and emotional strength.</p>
          </div>
          <div className="col-md-4">
            <Helmet>
              <style type="text/css">{`
                .col-md-4 > * {
                  margin-bottom: 1rem;
                }
                .card {
                  border: 0;
                  margin-bottom: 1rem;
                }
                .card-container {
                  margin-top: 3rem;
                }
                .card-img-top {
                  border-radius: 1rem;
                }
              `}
              </style>
            </Helmet>
            <div className="card-container">
              <Card className="text-white">
                <Card.Img variant="top" src={images[132]} alt="Outdoor play" />
                <Card.ImgOverlay>
                  <Card.Title>Outdoor play</Card.Title>
                </Card.ImgOverlay>
              </Card>
              <Card className="text-white">
                <Card.Img variant="top" src={images[97]} alt="Music lessions" />
                <Card.ImgOverlay>
                  <Card.Title>Music lessions</Card.Title>
                </Card.ImgOverlay>
              </Card>
              <Card className="text-white">
                <Card.Img variant="top" src={images[129]} alt="Water play" />
                <Card.ImgOverlay>
                  <Card.Title>Water play</Card.Title>
                </Card.ImgOverlay>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default aTypicalDay
